export default {
  admin_noDataForSelectionParametars: 'Aucune donnée pour les paramètres sélectionnés',
  betslip_betslip: 'paris',
  betslip_maxBetAmount: 'Montant maximal du pari',
  betslip_maxLengthIs: 'La longueur maximale est',
  betslip_maxWinLimit: 'Limite maximale de gain',
  betslip_minLengthIs: 'La longueur minimale est',
  betslip_stake: 'mise',
  betslip_startBettingMessage: 'Sélectionnez le résultat correspondant pour commencer à parier',
  betslip_ticketResolving: 'résolution de ticket',
  betslip_totalCombinations: 'total des combinaisons',
  betslip_totalStake: 'mise totale',
  betslip_ticketAccepted: 'ticket accepté',
  betslip_balanceWarning: 'Crédit insuffisant sur votre solde.',
  betslip_maxTicketAmount: 'Montant maximal du ticket',
  betslip_minBetAmount: 'Montant minimal du pari',
  betslip_minTicketAmount: 'Montant minimal du ticket',
  betslip_stakePerBet: 'Mise par pari',
  betslip_toReturn: 'Gain potentiel',
  betslip_invalidDrawType: 'Type de tirage invalide fourni',
  betslip_invalidOutcomeNumbers: 'Nombres joués invalides pour le type de résultat sélectionné',
  betslip_invalidStandardBet: "Le pari standard n'est pas valide",
  betslip_missingRequestUuid: 'UUID de requête manquant',
  betslip_numbersRange: 'Les numéros joués doivent être dans la plage du tournoi',
  betslip_sameDrumBonusBall: "Impossible d'envoyer une boule bonus pour le même tambour",
  betslip_haveOnBetslip: 'Déjà sur le ticket de pari',
  betslip_maxBetCountIs: 'Le nombre maximal de paris est',
  betslip_oddsHaveChangedFrom: 'Les cotes ont changé de',
  betslip_acceptAndPayin: 'Accepter et payer',
  betslip_oddsHaveChanged: 'Les cotes ont changé',
  betslip_outcomeIsAlready: 'Le résultat est déjà ajouté au ticket de pari',
  game_NumbersBetting: 'Loterie',
  general_accept: 'accepter',
  general_all: 'tout',
  general_bonus: 'bonus',
  general_cancel: 'annuler',
  general_check: 'vérifier',
  general_close: 'fermer',
  general_combo: 'combo',
  general_country: 'pays',
  general_currentlyUnavailable: 'Les paris sur cet événement sont actuellement indisponibles.',
  general_deleteShort: 'suppr.',
  general_error: 'erreur',
  general_event: 'événement',
  general_invalidTicketId: 'ID de ticket invalide',
  general_lastTickets: 'derniers tickets',
  general_lost: 'perdu',
  general_market: 'marché',
  general_maxWinShort: 'gain max',
  general_number: 'numéro',
  general_numbers: 'numéros',
  general_odd: 'cote',
  general_open: 'ouvert',
  general_payIn: 'payer',
  general_payinTax: 'taxe de paiement',
  general_payout: 'paiement',
  general_payoutAmount: 'montant du paiement',
  general_payoutTax: 'taxe sur le paiement',
  general_pick: 'choix',
  general_possiblePayoutTax: 'taxe possible sur le paiement',
  general_possibleWin: 'gain possible',
  general_requiredField: 'champ requis',
  general_results: 'résultats',
  general_search: 'recherche',
  general_selection: 'sélection',
  general_signIn: 'connexion',
  general_single: 'simple',
  general_ticket: 'ticket',
  general_ticketCheck: 'vérification de ticket',
  general_time: 'temps',
  general_totalOdds: 'cotes totales',
  general_winnings: 'gains',
  general_won: 'gagné',
  general_pleaseWait: 'veuillez patienter',
  general_today: "aujourd'hui",
  general_clear: 'effacer',
  general_edit: 'modifier',
  general_reset: 'réinitialiser',
  general_save: 'sauvegarder',
  general_selectAll: 'tout sélectionner',
  general_totalTickets: 'tickets totaux',
  general_add: 'ajouter',
  general_ticketCode: 'code du ticket',
  general_updateBet: 'mettre à jour le pari',
  general_payedout: 'payé',
  general_random: 'aléatoire',
  general_addBet: 'ajouter un pari',
  general_more: 'plus',
  general_info: 'info',
  general_successs: 'succès',
  general_categories: 'catégories',
  general_others: 'autres',
  general_popular: 'populaire',
  general_success: 'succès',
  general_ticketId: 'ID de ticket',
  general_all_countries: 'tous les pays',
  general_popular_lottery: 'loterie populaire',
  general_tournamentCancelled: 'tournoi annulé',
  general_in_play: 'en cours',
  general_warning: 'avertissement',
  general_clearAll: 'Tout effacer',
  general_refresh: 'actualiser',
  general_sortByCategories: 'Trier par catégories',
  general_sortByTime: 'Trier par heure',
  general_translations: 'Traductions',
  general_betslip: 'Ticket de pari',
  general_bonus_balls: 'Boules bonus',
  general_pay_in: 'Payer',
  general_stake_per_bet: 'Mise par pari',
  general_back: 'Retour',
  general_bet: 'Pari',
  general_to: 'à',
  general_betID: 'ID de pari',
  general_eventID: "ID d'événement",
  general_outcomeID: 'ID de résultat',
  general_match_locked: 'Match verrouillé',
  general_name: 'Nom',
  general_offer: 'Offre',
  general_selectAnOption: 'Sélectionnez une option',
  general_selectionContent: 'Contenu de la sélection',
  general_type: 'Type',
  general_noDataAvailable: 'Aucune donnée disponible pour les paramètres spécifiés !',
  general_oddsMgmt: 'Gestion des cotes',
  general_quickPick: 'Choix rapide',
  general_internalError: 'Erreur interne',
  general_status: 'Statut',
  general_id: 'ID',
  general_deleteLockedBets: 'Supprimer les paris verrouillés ?',
  general_acceptAndProceed: 'Accepter et continuer',
  general_min_payment_per_ticket_rule: 'Le paiement minimum par ticket est de {value}.',
  general_max_payment_per_ticket_rule: 'Le paiement maximum par ticket est de {value}.',
  general_max_bet_amount_rule: 'Le montant maximal du pari est de {value}.',
  general_min_bet_amount_rule: 'Le montant minimal du pari est de {value}.',
  general_noInternet: 'Vous êtes hors ligne. Veuillez vérifier votre connexion internet.',
  general_application: 'Application',
  general_channel: 'Canal',
  general_category: 'Catégorie',
  general_tournament: 'Tournoi',
  general_player: 'Joueur',
  general_operator: 'Opérateur',
  general_betshop: 'Point de pari',
  general_hour: 'Heure',
  general_week: 'Semaine',
  general_month: 'Mois',
  general_maxPaymentAmount: 'Montant maximal du paiement',
  general_maxTicketCount: 'Nombre maximal de tickets',
  general_maxSameNumbersSelection: 'Nombre maximal de paris avec les mêmes numéros',
  general_futureBets: 'Paris futurs',
  general_future_bet: 'Pari futur',
  general_stake_with_future_bets: 'Mise avec tous les paris futurs',
  nb_bonusBall: 'boule bonus',
  shop_betIsNotFound: 'Pari introuvable',
  shop_outcomeIsNotInOffer: "Le résultat n'est pas dans l'offre",
  shop_matchWithId: "Match avec l'ID",
  shop_notfoundAlreadyStarted: 'Introuvable ou déjà commencé',
  shop_allEventsStarted: 'Tous les événements ont déjà commencé',
  shop_webCodeInvalidOrExpired: 'Le code web est invalide ou expiré',
  shop_nextEventStartingIn: 'Prochain événement commence dans',
  shop_newTicket: 'Nouveau ticket',
  shop_print: 'Imprimer',
  shop_results: 'Résultats',
  shop_random: 'Aléatoire',
  shop_fullOffer: 'Offre complète',
  shop_resetTicket: 'Réinitialiser le ticket',
  shop_ticketType: 'Type de ticket',
  shop_offerResultsPrint: 'Offre/résultats Imprimer',
  shop_printPreview: 'Aperçu avant impression',
  shop_offerSelect: "Sélection d'impression d'offre",
  shop_resultsSelect: "Sélection d'impression de résultats",
  shop_bettingDisabled: 'Paris désactivés',
  shop_printOffer: "Imprimer l'offre",
  shop_resultsOffer: 'Offre des résultats',
  shop_removeDuplicateNumbers: 'Supprimer les numéros en double',
  shop_printPreviewIsGenerating: 'Veuillez patienter. Aperçu en cours de génération',
  shop_sameLocationPayoutRestriction: "Le ticket ne peut être payé qu'à partir du point de pari où il a été joué",
  shop_unknownTicketCheck: 'Ticket inconnu',
  shop_addToBetslip: 'Ajouter au ticket de pari',
  shop_removeBallsSelection: 'Supprimer la sélection de boules',
  general_system: 'Système',
};
